var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('select', {
    staticClass: "form-control select2",
    attrs: {
      "multiple": _vm.multiple
    }
  }, _vm._l(_vm.options, function (option, i) {
    return _c('option', {
      key: i,
      domProps: {
        "value": option.value
      }
    }, [_vm._v(_vm._s(option.text))]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }