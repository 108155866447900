var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom gutter-b"
  }, [_c('div', {
    staticClass: "card-body p-0"
  }, [_c('div', {
    staticClass: "row justify-content-center pt-8 px-8 pt-md-27 px-md-0"
  }, [_c('div', {
    staticClass: "col-md-10"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row"
  }, [_c('h1', {
    staticClass: "display-4 font-weight-boldest mb-10"
  }, [_vm._v(_vm._s(_vm.$t('CHECKOUT PAGE')))]), _c('div', {
    staticClass: "d-flex flex-column align-items-md-end px-0"
  }, [_c('span', {
    staticClass: "d-flex flex-column align-items-md-end opacity-70"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('Place your order')))]), _c('span', [_vm._v(_vm._s(_vm.$t('we will elaborate it in few days')))])])])]), _c('div', {
    staticClass: "border-bottom w-100"
  })])]), _c('div', {
    staticClass: "row justify-content-center py-8 px-8 py-md-10 px-md-0"
  }, [_vm.items.length > 0 ? _c('div', {
    staticClass: "col-md-10"
  }, [_c('div', {
    staticClass: "table-responsive"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "pl-0 font-weight-bold text-muted text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t('Order item')))]), _c('th', {
    staticClass: "text-right font-weight-bold text-muted text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t('Duration')))]), _c('th', {
    staticClass: "text-right font-weight-bold text-muted text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t('Price')))]), _c('th', {
    staticClass: "text-right pr-0 font-weight-bold text-muted text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t('Action')))])])]), _c('tbody', _vm._l(_vm.items, function (item, i) {
    return _c('tr', {
      key: i,
      staticClass: "font-weight-boldest"
    }, [_c('td', {
      staticClass: "border-0 pl-0 pt-7 d-flex align-items-center"
    }, [_c('div', {
      staticClass: "flex-shrink-0 mr-4 bg-light"
    }, [_c('country-flag', {
      attrs: {
        "country-iso": item.countries[0]
      }
    })], 1), _c('div', {
      staticClass: "ml-4"
    }, [_vm._v(_vm._s(_vm.itemTitle(item)))])]), _c('td', {
      staticClass: "text-right pt-7 align-middle"
    }, [_c('Select2', {
      attrs: {
        "options": _vm.durations,
        "placeholder": 'Select duration',
        "default": item.duration
      },
      on: {
        "change": function change($event) {
          return _vm.changeValue($event, i);
        }
      }
    })], 1), _vm.priceMap[item.id] != undefined ? _c('td', {
      staticClass: "text-primary pr-0 pt-7 text-right align-middle"
    }, [_vm._v(" " + _vm._s(parseFloat(_vm.priceMap[item.id][item.duration])) + " " + _vm._s(_vm.currency) + " ")]) : _c('td', {
      staticClass: "text-primary pr-0 pt-7 text-right align-middle"
    }, [_vm._v(" " + _vm._s(parseFloat(item.price)) + " " + _vm._s(_vm.currency) + " ")]), _c('td', {
      staticClass: "text-right pt-7 align-middle"
    }, [_c('button', {
      staticClass: "btn btn-light-success font-weight-bolder",
      on: {
        "click": function click($event) {
          return _vm.removeItem(item.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('Remove')) + " ")])])]);
  }), 0)])])]) : _c('div', {
    staticClass: "col-md-10"
  }, [_c('p', {
    staticClass: "h3"
  }, [_vm._v(_vm._s(_vm.$t('No elements in the cart.')))])])]), _c('div', {
    staticClass: "row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0 mx-0"
  }, [_c('div', {
    staticClass: "col-"
  }, [_c('div', {
    staticClass: "font-weight-bold text-muted text-uppercase text-right"
  }, [_vm._v(_vm._s(_vm.$t('Total')))]), _c('div', {
    staticClass: "text-primary font-size-h3 font-weight-boldest text-right"
  }, [_vm._v(_vm._s(_vm.total) + " ")])])]), _c('div', {
    staticClass: "row justify-content-center py-8 px-8 py-md-10 px-md-0"
  }, [_c('div', {
    staticClass: "col-md-10"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "btn btn-primary font-weight-bold",
    class: {
      'spinner spinner-light spinner-right': _vm.isLoadingConfirmation
    },
    attrs: {
      "type": "button",
      "disabled": _vm.isDisabledConfirmOrder
    },
    on: {
      "click": function click($event) {
        return _vm.confirmOrder();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('Confirm Order Details')))])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }