<template>
    <select class="form-control select2" :multiple="multiple">
        <option v-for="(option, i) in options" :key="i" :value="option.value">{{ option.text }}</option>
    </select>
</template>

<script>
import $ from 'jquery';
import select2 from 'select2';
window.select2 = select2;

export default {
    props: {
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: "Placeholder text"
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        default: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            select2data: []
        }
    },
    mounted() {
        this.formatOptions()
        let vm = this
        let select = $(this.$el)
        select.select2({
            placeholder: this.placeholder,
            width: '100%',
        }).on('change', function () {
            vm.$emit('change', select.val())
        })
        select.val(this.default)
    },
    destroyed: function () {
        $(this.$el).off().select2('destroy')
    },
    methods: {
        formatOptions() {
            this.select2data.push({ id: '', text: 'Select' })
            for (let key in this.options) {
                this.select2data.push({ id: key, text: this.options[key] })
            }
        }
    }

}
</script>