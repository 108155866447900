<template>
  <div class="card card-custom gutter-b">
    <div class="card-body p-0">
      <!-- begin: Invoice-->
      <!-- begin: Invoice header-->
      <div class="row justify-content-center pt-8 px-8 pt-md-27 px-md-0">
        <div class="col-md-10">
          <div class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
            <h1 class="display-4 font-weight-boldest mb-10">{{ $t('CHECKOUT PAGE') }}</h1>
            <div class="d-flex flex-column align-items-md-end px-0">
              <span class=" d-flex flex-column align-items-md-end opacity-70">
                <span>{{ $t('Place your order') }}</span>
                <span>{{ $t('we will elaborate it in few days') }}</span>
              </span>
            </div>
          </div>
          <div class="border-bottom w-100"></div>
        </div>
      </div>
      <!-- end: Invoice header-->

      <!-- begin: Invoice body-->
      <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
        <div v-if="items.length > 0" class="col-md-10">
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="pl-0 font-weight-bold text-muted  text-uppercase">{{ $t('Order item') }}</th>
                  <th class="text-right font-weight-bold text-muted text-uppercase">{{ $t('Duration') }}</th>
                  <th class="text-right font-weight-bold text-muted text-uppercase">{{ $t('Price') }}</th>
                  <th class="text-right pr-0 font-weight-bold text-muted text-uppercase">{{ $t('Action') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(   item, i   ) in    items   " :key="i" class="font-weight-boldest">
                  <td class="border-0 pl-0 pt-7 d-flex align-items-center">
                    <!--begin::Symbol-->
                    <div class="flex-shrink-0 mr-4 bg-light">
                      <country-flag :country-iso="item.countries[0]"></country-flag>
                    </div>
                    <!--end::Symbol-->
                    <div class="ml-4">{{ itemTitle(item) }}</div>

                  </td>
                  <td class="text-right pt-7 align-middle">
                    <Select2 :options="durations" :placeholder="'Select duration'" :default="item.duration"
                      @change="changeValue($event, i)">
                    </Select2>
                  </td>
                  <td v-if="priceMap[item.id] != undefined" class="text-primary pr-0 pt-7 text-right align-middle">
                    {{ parseFloat(priceMap[item.id][item.duration]) }} {{
                      currency }}
                  </td>
                  <td v-else class="text-primary pr-0 pt-7 text-right align-middle">
                    {{ parseFloat(item.price) }} {{ currency }}
                  </td>
                  <td class="text-right pt-7 align-middle">
                    <button class="btn btn-light-success font-weight-bolder" @click="removeItem(item.id)">
                      {{ $t('Remove') }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="col-md-10">
          <p class="h3">{{ $t('No elements in the cart.') }}</p>
        </div>
      </div>

      <!-- end: Invoice body-->

      <!-- begin: Invoice footer-->
      <div class="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0 mx-0">
        <div class="col-">
          <div class="font-weight-bold text-muted  text-uppercase text-right">{{ $t('Total') }}</div>
          <div class="text-primary font-size-h3 font-weight-boldest text-right">{{ total }}
          </div>
        </div>
      </div>
      <!-- end: Invoice footer-->

      <!-- begin: Invoice action-->
      <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
        <div class="col-md-10">
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-primary font-weight-bold"
              :class="{ 'spinner spinner-light spinner-right': isLoadingConfirmation }" :disabled="isDisabledConfirmOrder"
              @click="confirmOrder()">{{ $t('Confirm Order Details')
              }}</button>
          </div>
        </div>
      </div>
      <!-- end: Invoice action-->

      <!-- end: Invoice-->
    </div>
  </div>
</template>

<script>
import Select2 from "@/view/components/inputs/Select2.vue";
import { backendErrorSwal, shopConfirmSwal } from "@/core/helpers/swal";
import Swal from "sweetalert2";
import { mapState, mapGetters, mapActions } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CountryFlag from "@/view/components/CountryFlag.vue";
import OrdersService from '@/core/services/risk/orders.service';
import moment from 'moment'

export default {
  components: {
    CountryFlag,
    Select2
  },
  data() {
    return {
      productTypes: {
        'report': 'd1bd241c-842e-4056-8c95-74a7b1a958e0',
        'map': 'd75501fd-27f5-4b18-bd26-b2b314d0eaff'
      },
      isLoadingPastOrders: true,
      isLoadingConfirmation: false
    };
  },
  computed: {
    ...mapState("cart", ["items", "currency", "durations", "duration", "priceMap", "id"]),
    ...mapState("user", ["user"]),
    ...mapGetters("cart", ["getItemsForCountry"]),
    ...mapGetters("constants", ["countriesMap"]),
    ...mapGetters("user", ["getUserID", "dateFormat"]),

    totalItems() {
      return this.items.length
    },
    total() {
      let total = 0;

      for (const item of this.items) {
        if (this.priceMap[item.id]) {
          total += parseFloat(this.priceMap[item.id][item.duration])
        }
        else {
          total += parseFloat(item.price)
        }
      }
      return total + this.currency
    },
    isDisabledConfirmOrder() {
      return this.isLoadingConfirmation || !this.items.length
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Checkout' }]);

    // prezzi per tipologia
    // feature ultimi ordini in pending
    OrdersService.getAll({
      createdBy: this.getUserID,
      sortBy: "-updated_at",
      status: 'submitted'
    }).then(res => {
      let lastOrders = res.results;
      this.lastOrders = lastOrders;
      this.isLoadingPastOrders = false;
    }).catch(err => {
      console.log('@mounted', err)
      this.isLoadingPastOrders = false;
    })
  },
  methods: {
    ...mapActions("cart", ["setDuration", "removeItem", "removeAllItems", "saveOrder", "initCart"]),
    ...mapActions("capabilities", ["loadCapabilities"]),

    changeValue(value, index) {
      if (value && index != undefined)
        this.setDuration({ duration: value, i: index })
    },
    itemTitle(item) {
      return item.name + ' ' + this.$t("in") + ' ' + this.countriesMap[item.countries[0]].name;
    },
    dateParse(date) {
      return moment(date).format(this.dateFormat).split('-').join('/') + ' - ' + moment(date).format('hh:mm:ss')
    },
    confirmOrder() {
      shopConfirmSwal(this.total).then(res => {
        let self = this;
        if (res.isConfirmed) {
          this.isLoadingConfirmation = true;
          // Piazza l'ordine
          let body = {
            items: []
          }

          this.items.forEach(item => {
            let attributes = item;
            attributes.date_range = item.duration; // TODO: da gestire
            attributes.monitoring = true

            // possiede categorie solo se è un report
            let element = {
              product_type: this.productTypes[item.type],
              quantity: 1,
              attributes: attributes,
              status: "submitted"
            }
            body.items.push(element);

            // Se non ho i prezzi dell'elemento li aggiugno
            if (!this.priceMap[item.id]) {
              let params = {
                productType: this.productTypes[item.type],
                country: item.countries[0],
              }
              if (item.categories?.[0])
                params.category = item.categories[0];

              OrdersService.getProductPrice(params).then(res => {
                this.priceMap[item.id] = res
              }).catch(err => {
                console.log('@getPrices', err)
              })
            }
          })
          OrdersService.checkout(body, this.id).then(() => {
            Swal.fire({
              icon: 'success',
              title: self.$t("Order created!"),
              timer: 2000,
              timerProgressBar: true,
              showConfirmButton: false
            }).then(() => {
              this.loadCapabilities() // Bisogna ricaricare le capabilities per aggiornare le pending
              this.initCart({ userId: this.getUserID, companyId: this.user.selected_company })
              self.$router.push({ name: 'detail-physical-orders', params: { userID: this.user.persona } })
            })
          }).catch(err => {
            backendErrorSwal(err, self.$t("Something went wrong in order creation"));
            console.error('@confirmOrder', err)
          }).finally(() => {
            this.isLoadingConfirmation = false;
          });
        }
      })
    }
  },
}
</script>
